import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function ArrowRight(props) {
  return __jsx("svg", _extends({
    width: "12",
    height: "13",
    viewBox: "0 0 12 13",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("mask", {
    id: "mask0_8378_1828",
    style: {
      maskType: "alpha"
    },
    maskUnits: "userSpaceOnUse",
    x: "0",
    y: "0",
    width: "12",
    height: "13"
  }, __jsx("rect", {
    y: "0.5",
    width: "12",
    height: "12",
    fill: "currentColor"
  })), __jsx("g", {
    mask: "url(#mask0_8378_1828)"
  }, __jsx("path", {
    d: "M4.01211 11.9001L2.72461 10.6126L6.83711 6.5001L2.72461 2.3876L4.01211 1.1001L9.41211 6.5001L4.01211 11.9001Z",
    fill: "currentColor"
  })));
}
export default ArrowRight;