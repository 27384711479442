export var BREADCRUMBS_VAR = {
  bgColor: {
    "private": '--_nva-breadcrumbs--bg-color',
    "public": '--nva-breadcrumbs--bg-color',
    value: 'hsl(var(--g-color-secondary-950))'
  },
  fontColor: {
    "private": '--_nva-breadcrumbs--font-color',
    "public": '--nva-breadcrumbs--font-color',
    value: 'hsl(var(--g-color-white))'
  },
  arrowColor: {
    "private": '--_nva-breadcrumbs--arrow-color',
    "public": '--nva-breadcrumbs--arrow-color',
    value: 'hsl(var(--g-color-secondary))'
  }
};