var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import styles from './Breadcrumbs.style';
import Anchor from '../Anchor';
import { useRouter } from 'next/router';
import ArrowRight from '../../../static/images/icons/ArrowRight';
var Breadcrumbs = function Breadcrumbs(_ref) {
  var className = _ref.className,
    baseRoute = _ref.baseRoute,
    data = _ref.data;
  var _useRouter = useRouter(),
    query = _useRouter.query;
  var getBaseUrl = function getBaseUrl() {
    return "/".concat(query === null || query === void 0 ? void 0 : query.slug[0]);
  };
  var getBreadcrumbs = function getBreadcrumbs() {
    var _query$slug;
    if (data.__typename === 'Blog') {
      var _data$blogCategory;
      return __jsx(React.Fragment, null, __jsx("li", {
        className: "nva-breadcrumbs__item",
        "aria-hidden": "true"
      }, __jsx(ArrowRight, {
        "aria-hidden": "true",
        className: "nva-breadcrumbs__arrow"
      })), __jsx("li", {
        className: "nva-breadcrumbs__item"
      }, "".concat(data === null || data === void 0 ? void 0 : (_data$blogCategory = data.blogCategory) === null || _data$blogCategory === void 0 ? void 0 : _data$blogCategory.categoryName)));
    }

    // Removes the first portion as that is being handled by the getBaseUrl
    var breadcrumbParts = query === null || query === void 0 ? void 0 : (_query$slug = query.slug) === null || _query$slug === void 0 ? void 0 : _query$slug.filter(function (slug) {
      return slug !== (query === null || query === void 0 ? void 0 : query.slug[0]);
    });
    return breadcrumbParts.map(function (breadcrumbPart) {
      return __jsx(React.Fragment, null, __jsx("li", {
        className: "nva-breadcrumbs__item",
        "aria-hidden": "true"
      }, __jsx(ArrowRight, {
        "aria-hidden": "true",
        className: "nva-breadcrumbs__arrow"
      })), __jsx("li", {
        className: "nva-breadcrumbs__item"
      }, breadcrumbPart.replaceAll('-', ' ')));
    });
  };
  return __jsx("nav", {
    "aria-label": "Breadcrumbs",
    className: cn('nva-breadcrumbs', className)
  }, __jsx("div", {
    className: "l-grid"
  }, __jsx("div", {
    className: "l-col-full"
  }, __jsx("ol", null, __jsx("li", {
    className: "nva-breadcrumbs__item"
  }, __jsx(Anchor, {
    removeDefaultStyles: true,
    to: getBaseUrl()
  }, baseRoute)), getBreadcrumbs()))));
};
export default styled(Breadcrumbs).withConfig({
  componentId: "sc-1hsis82-0"
})(["", ";"], styles);